import React from 'react'

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SearchAppContext } from '@standards/context'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';


const FilterWapper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: 'none',

}));

const ActiveFilterLine = styled(Chip)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "5px",
    marginRight: "5px",
    height: "auto",
    textTransform: 'capitalize',
    "& span": {
        whiteSpace: "break-spaces",
        [theme.breakpoints.up('md')]: {
            whiteSpace: "nowrap"
        }
    }
}));

const ActiveFilter = styled(Chip)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    height: "auto",
    "& span": {
        whiteSpace: "break-spaces"
    }
}));

function AppliedFilters(props) {

    const dispatch = React.useContext(SearchAppContext);
    const facetUsedObject = dispatch({ type: 'GET', field: 'FACET_USED_OBJECT' })?.data;
    const advancedFields = dispatch({ type: 'GET', field: 'ADVANCED_FIELDS' })?.data;

    const handleDelete = (fq) => {
        dispatch({ type: 'SET', function: 'REMOVE_FQ', fq: fq })
    };

    const handleAdvancedFieldsDelete = (field) => {
        dispatch({ type: 'SET', function: 'REMOVE_ADVANCED_FIELD', field: field })
    }

    const getStatus = () => {
        let status = dispatch({ type: 'GET', field: 'STATUS' }).data
        if (status == "allStatus") return "ANY status."
        else return status.toUpperCase() + " status."
    }

    const getSite = () => {
        let site = dispatch({ type: 'GET', field: 'COLLECTION' }).data

        if (site == "allSites") return "DIRECTIVES, STANDARDS, and NNSA sites,"
        else return site.toUpperCase() + " site,"
    }

    const getSearchedTerm = () => {
        let term = dispatch({ type: 'GET', field: 'SEARCHED_TERM' }).data
        if (term == "") return ""
        else return ` that contains the term "${term}"`
    }

    const facetUsedView = () => {
        if (!facetUsedObject) return

        return (Object.entries(facetUsedObject).map((filter) => {
            const facetObj = dispatch({ type: 'GET', field: 'FACET_USED_OBJECT_BY_FIELD', facetFieldName: filter[0] })?.data
            let label = facetObj.short || facetObj.alias
            return (
                <>
                    {
                        filter[1][2].map((element, index) => {
                            let activeFilterElement = ""
                            if (element[1])
                                activeFilterElement = (
                                    <span >
                                        {label}: {element[1]}
                                    </span>
                                )
                            else if (element[2] && element[3]) {
                                activeFilterElement = (
                                    <span>
                                        {label}: {new Date(element[2]).toLocaleDateString("en-US") + " TO " + new Date(element[3]).toLocaleDateString("en-US")}
                                    </span>
                                )
                            }

                            return (
                                <Tab
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    component="span"
                                    sx={
                                        { opacity: 1, padding: "0px", maxWidth: "100%" }
                                    }

                                    label={
                                        <ActiveFilterLine label={activeFilterElement} onDelete={() => handleDelete(filter[1][1][index])} />
                                    }
                                />
                            )
                        })
                    }
                </>
            )
        })
        )
    }

    const advancedFieldsView = (type) => {

        if (type === 'text') {
            return (
                advancedFields
                    .filter(advancedField => advancedField.type === 'text')
                    .map(advancedField => {
                        const label = advancedField?.short || advancedField?.alias
                        const displayName = `${label}: ${advancedField.value}`;
                        return (
                            <>
                                <Tab
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    component="span"
                                    sx={
                                        { opacity: 1, padding: "0px", maxWidth: "100%" }
                                    }
                                    label={
                                        <ActiveFilterLine label={displayName} onDelete={() => handleAdvancedFieldsDelete(advancedField.field)} />
                                    }
                                />
                            </>
                        )
                    })
            )
        }

        else if (type === 'date') {
            const advancedFieldsDate = advancedFields.filter(advancedField => advancedField.type === 'date')

            const advancedFieldsCombinedByField = {};
            for (const obj of advancedFieldsDate) {
                const field = obj.field;
                if (!advancedFieldsCombinedByField[field]) {
                    const label = obj?.short || obj?.alias
                    advancedFieldsCombinedByField[field] = {};
                    advancedFieldsCombinedByField[field]['label'] = label
                }
                const fieldName = obj.name.split('_').reverse()[0]
                advancedFieldsCombinedByField[field][fieldName] = obj.value
            }

            return (
                Object.entries(advancedFieldsCombinedByField)
                    .map(([field, data]) => {

                        let stratDate, endDate = '*'

                        if (data.hasOwnProperty('start'))
                            stratDate = data.start.replace(/(\d{4})-(\d{2})-(\d{2})/, (match, year, month, day) => `${month}-${day}-${year}`);
                        if (data.hasOwnProperty('end'))
                            endDate = data.end.replace(/(\d{4})-(\d{2})-(\d{2})/, (match, year, month, day) => `${month}-${day}-${year}`);


                        const displayName = `${data.label}: ${stratDate} TO ${endDate}`.replaceAll("-", "/")

                        return (
                            <>
                                <Tab
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    component="span"
                                    sx={
                                        { opacity: 1, padding: "0px", maxWidth: "100%" }
                                    }
                                    label={
                                        <ActiveFilterLine label={displayName} onDelete={() => handleAdvancedFieldsDelete(field)} />
                                    }
                                />
                            </>
                        )
                    })
            )
        }
    }

    const NoResultView = () => {
        return (
            <Box>
                Search Criteria:
                <div>
                    You are searching for documents <b>{getSearchedTerm()}</b> In <b>{getSite()}</b> that match <b>{getStatus()}</b>
                </div>
                <div>
                    Also, you have selected the following filters:
                    <div>
                        {
                            Object.entries(facetUsedObject).map((filter) => {
                                let name = filter[1][0]['alias']
                                return (
                                    <FilterWapper>
                                        <b>{name}</b>:{" "}
                                        {
                                            filter[1][2].map((element, index) => {
                                                let activeFilterElement = ""
                                                if (element[1])
                                                    activeFilterElement = (
                                                        <span >
                                                            {element[1]}
                                                        </span>
                                                    )
                                                else if (element[2] && element[3]) {
                                                    activeFilterElement = (
                                                        <span>
                                                            {new Date(element[2]).toLocaleDateString("en-US") + " TO " + new Date(element[3]).toLocaleDateString("en-US")}
                                                        </span>
                                                    )
                                                }

                                                return (
                                                    <ActiveFilter label={activeFilterElement} onDelete={() => handleDelete(filter[1][1][index])} />
                                                )
                                            })
                                        }
                                    </FilterWapper>
                                )

                            })
                        }
                    </div>
                </div>
            </Box>
        )
    }

    const TableView = () => {

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    "& div": {
                        flexWrap: 'wrap'
                    }
                }}
            >
                <Tabs
                    orientation="horizontal"
                // variant="scrollable"
                // allowScrollButtonsMobile={true}
                >
                    {
                        facetUsedView()
                    }
                    {
                        advancedFieldsView('text')
                    }
                    {
                        advancedFieldsView('date')
                    }
                </Tabs>
            </Box>

        )

    }

    return (
        <>
            {props.view === 'NoResult' ? NoResultView() : ''}
            {props.view === 'Table' ? TableView() : ''}
        </>
    )
}

AppliedFilters.defaultProps = {
    view: "NoResult"
}

export default AppliedFilters;