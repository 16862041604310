import React from 'react';
import { resolveExtension } from '@plone/volto/helpers/Extensions/withBlockExtensions';
import config from '@plone/volto/registry';
import { hasNonValueOperation, hasDateOperation } from '@plone/volto/components/manage/Blocks/Search/utils';
import { Modal } from '@standards/helpers';
import { Button } from 'semantic-ui-react';

import { Icon } from '@plone/volto/components';
import filter from '@plone/volto/icons/filter.svg';

import { useViewport } from "@standards/hooks";

import './Facets.less'

const showFacet = (index) => {
    const { values } = index;
    return index
        ? hasNonValueOperation(index.operations || []) ||
            hasDateOperation(index.operations || [])
            ? true
            : values && Object.keys(values).length > 0
        : values && Object.keys(values).length > 0;
};

const Facets = (props) => {
    const {
        querystring,
        data = {},
        facets,
        setFacets,
        facetWrapper,
        isEditMode,
    } = props;
    const { search } = config.blocks.blocksConfig;
    const { isMobile } = useViewport()

    const FacetWrapper = facetWrapper;
    const query_to_values = Object.assign(
        {},
        ...(data?.query?.query?.map(({ i, v }) => ({ [i]: v })) || []),
    );

    const facetView = () => {
        return <div>
            {data?.facets
                ?.filter((facetSettings) => !facetSettings.hidden)
                .map((facetSettings) => {
                    // const field = facetSettings.field[0];
                    const field = facetSettings?.field?.value;
                    const index = querystring.indexes[field] || {};
                    const { values = {} } = index;

                    const isFacetCountEnabled = props.facetsCount ? true : false;
                    const facetCount = props.facetsCount?.[facetSettings?.field?.value] || 0;

                    let choices = Object.keys(values)
                        .map((name) => ({
                            value: name,
                            label: values[name].title,
                        }))
                        // filter the available values based on the allowed values in the
                        // base query
                        .filter(({ value }) =>
                            query_to_values[field]
                                ? query_to_values[field].includes(value)
                                : true,
                        );

                    choices = choices.sort((a, b) =>
                        a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }),
                    );

                    const isMulti = facetSettings.multiple;
                    const selectedValue = facets[facetSettings?.field?.value];

                    // TODO :handle changing the type of facet (multi/nonmulti)

                    const { view: FacetWidget, stateToValue } = resolveExtension(
                        'type',
                        search.extensions.facetWidgets.types,
                        facetSettings,
                    );

                    let value = stateToValue({ facetSettings, index, selectedValue });

                    const {
                        rewriteOptions = (name, options) => options,
                    } = search.extensions.facetWidgets;

                    return FacetWrapper && (isEditMode || showFacet(index)) ? (
                        <FacetWrapper key={facetSettings['@id']}>
                            <FacetWidget
                                facet={facetSettings}
                                facetCount={facetCount}
                                isFacetCountEnabled={isFacetCountEnabled}
                                choices={rewriteOptions(
                                    facetSettings?.field?.value,
                                    choices,
                                ).filter(({ _, value }) => {
                                    if (isFacetCountEnabled === false) return true;
                                    return Object.keys(facetCount?.data || {}).find(
                                        (key) => key === value,
                                    );
                                })} isMulti={isMulti}
                                value={value}
                                isEditMode={isEditMode}
                                onChange={(id, value) => {
                                    !isEditMode && setFacets({ ...facets, [id]: value });
                                }}
                            />
                        </FacetWrapper>
                    ) : (
                        ''
                    );
                })}
        </div>
    }

    return (
        <>
            {
                isMobile ? (<Modal
                    id='searchblock-facets-mobile-modal'
                    style={{ top: 0, right: 0, margin: 0, height: '100%' }}
                    trigger={<Button id="facets-modal-open-button"><Icon name={filter} size="2rem" /></Button>}
                    closeOnInnerModalClick={false}
                >
                    {facetView()}
                </Modal>) : (<>{facetView()}</>)
            }
        </>
    );
};

export default Facets;
