import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Paper } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import FilterListIcon from '@mui/icons-material/FilterList';
import DoneIcon from '@mui/icons-material/Done';

import { useLocalStorage } from '@standards/hooks';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height:'100%',
  p: 1,
  maxWidth:'90%',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
};

export default function MobileViewFilters(props) {

  

  const [open, setOpen] =  useLocalStorage("MobileViewFiltersModalState", false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
       <SpeedDial
        ariaLabel="Mobile Filter Menu"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex:9000 }}
        onClick={()=>{ (open) ? handleClose() : handleOpen() }}
        icon={ open ? <DoneIcon /> : <FilterListIcon />}
        
        
      ></SpeedDial>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 0,
        }}
      >
        <>
        {/* <Fade in={open}> */}
          <Box sx={style}>
            <Paper style={{maxHeight: '80%'}}>
              {props.children}
            </Paper>
          </Box>
        {/* </Fade> */}
        </>
      </Modal>
    </div>
  );
}