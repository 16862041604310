import React, { useState, useEffect, createContext } from 'react';
// import { Search as SearchReq } from 'requests'
import { getLocalStorage, setLocalStorage } from '@standards/hooks'
import { Api } from '@plone/volto/helpers';
import { getDefaultQuery, serialize, deserialize, updateDefaultQuery } from './helpers'
import { useHistory } from 'react-router-dom'
import ReactGA from "react-ga4";
import config from '@plone/volto/registry';

const defaultSearchTerm = "getall";

const API = new Api();

export const SearchAppContext = createContext();

export const SearchAppProvider = props => {

    const history = useHistory()

    // incase there is searchparms
    const initQuery = history.location.search || getLocalStorage('lastQuery') || null
    let query = updateDefaultQuery(deserialize(initQuery))

    const [isLoading, setIsLoading] = useState(true)
    const [isUpdate, setIsUpdate] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [forceReload, setForceReload] = useState()

    const [SearchResult, setSearchResult] = useState({})

    // keynotes
    const [searchParams, setSearchParams] = useState(query)
    const [fquery, setFQuery] = useState(query.fq)
    const [advancedFields, setAdvancedFields] = useState(query.advancedFields)


    if (history.location.pathname.startsWith('/searchapp') && history.location.search === "") {
        updateURL()
    }

    useEffect(() => {

        doSearchRequest()

    }, [searchParams, forceReload]);

    useEffect(() => {
        setSearchParams(prevState => ({
            ...prevState,
            fq: fquery
        }))
    }, [fquery]);

    useEffect(() => {
        setSearchParams(prevState => ({
            ...prevState,
            advancedFields: advancedFields
        }))
    }, [advancedFields]);

    function updateURL() {
        if (!history.location.pathname.startsWith('/searchapp')) return
        history.replace({ search: serialize(searchParams) })
    }

    const updateLastQuery = () => setLocalStorage('lastQuery', serialize(searchParams)) // setLocalStorage('lastQuery', searchParams);

    // Helpers -----------------------
    function doSearchRequest() {
        if (searchParams.WhichSite == null || searchParams.DirStatus == null) {
            return
        }

        setIsUpdate(true)


        const portalsURL = {
            "directives": `${config.settings.portals.getSiteURL('directives')}/++api++/@@searchapp`,
            "nnsa": `${config.settings.portals.getSiteURL('nnsa')}/++api++/@@searchapp`,
            "standards": `${config.settings.portals.getSiteURL('standards')}/++api++/@@searchapp`
        }

        const requestURL = searchParams.WhichSite in portalsURL ? portalsURL[searchParams.WhichSite] : portalsURL['standards']

        const searchParamsSeri = serialize(searchParams)

        API.get(requestURL, {
            params: searchParamsSeri,
        })
            .then(res => {

                const data = JSON.parse(res)

                ReactGA.send({
                    hitType: 'pageview', title: 'Searchapp',
                    page: history.location.pathname,
                    text_query: searchParams['SearchableText'],
                    status: searchParams['DirStatus'],
                    site: searchParams['WhichSite'],
                    op: searchParams['operator'],
                    query: searchParamsSeri
                });

                setIsLoading(true)

                setSearchResult(data)

                updateURL();
                updateLastQuery()

                setIsLoading(false)
                setIsUpdate(false)
                setHasError(false)

            })

            .catch(err => {
                if (err.message.includes('terminated')) return

                setHasError(true)
                setIsLoading(false)
                setIsUpdate(false)

                // addNotification('error', 'Something is wrong! Please try again later.')
            })
    }

    // SET FUNCTIONS ------------------
    const setDirStatus = (DirStatus) => {

        setSearchParams(prevState => ({
            ...prevState,
            DirStatus: DirStatus
        }))
    }

    const setWhichSite = (WhichSite) => {

        let schema = "default"

        if (WhichSite != "") schema = WhichSite + "_default"

        setSearchParams(prevState => ({
            ...prevState,
            WhichSite: WhichSite,
            schema: schema
        }))
    }

    const setPage = (page) => {
        setSearchParams(prevState => ({
            ...prevState,
            page: page
        }))
    }

    const setPerPage = (perPage) => {
        setSearchParams(prevState => ({
            ...prevState,
            perPage: perPage
        }))
    }


    const setSort = (field, direction) => {
        setSearchParams(prevState => ({
            ...prevState,
            sort: field + " " + direction
        }))
    }

    const setSchema = (schema) => {
        setSearchParams(prevState => ({
            ...prevState,
            schema: schema
        }))
    }

    const setOperator = (operator) => {
        setSearchParams(prevState => ({
            ...prevState,
            operator: operator
        }))
    }

    const setQuery = (query) => {

        if (query.SearchableText === "") {
            query.SearchableText = defaultSearchTerm
        }

        setSearchParams(query);
    }


    const setFQ = (newFQ) => {
        /*
            combinedFQ : string that represent filter selected
                +subjectareas:("Management and Operations")+subjectareas:("Safety")
                +subjectareas:("Security")+series:("400 Work Processes")+approvedDate:[2012-01-01T08:00:00.000Z TO 2013-01-01T08:00:00.000Z]

            SearchResult.facet[2] : object like representation of the applied filters and its associated information
            
            With each new incoming FQ:
            Check if newFQ is anywhere in SearchResult.facet[2]
                if found, that means the user unclicked it. so when looping through. Do not add it
                else add to combinedFQ 
                    Ignore widgetType == date << We do not want to recombined this kind.
        
        */

        let found = false
        let combinedFQ = ""
        Object.entries(SearchResult.facet[2]).forEach((filter) => {
            if (newFQ.includes(filter[0]) && filter[1][0].widgetType == "date") {
                // DO NOTHING

            } else {
                filter[1][1].forEach(element => {
                    if (element == "+" + newFQ) found = true
                    else combinedFQ += element
                })
            }
        })

        if (!found) combinedFQ += "+" + newFQ
        setFQuery(combinedFQ)
    }


    const addAdvancedField = (field) => {

        const index = advancedFields.findIndex(advancedField => advancedField.name === field.name);
        if (index === -1) {
            setAdvancedFields([...advancedFields, field]);
        } else {
            const updatedAdvancedFields = [...advancedFields];
            updatedAdvancedFields[index] = { ...advancedFields[index], value: field.value };
            setAdvancedFields(updatedAdvancedFields);
        }

    };


    const removeFQ = (fqToRemove) => {
        if (fquery.includes(fqToRemove)) {
            setFQuery(fquery.replace(fqToRemove, ""))
        }
    }

    const removeAdvancedFieldByField = (field) => {
        setQuery(prevQuery => ({
            ...prevQuery,
            advancedFields: prevQuery.advancedFields.filter(advancedField => advancedField.field !== field)
        }));
    };


    // RESET FUNCTIONS ------------------

    const resetSort = () => {
        setSearchParams(prevState => ({
            ...prevState,
            sort: ""
        }))
    }


    const resetFQs = () => {
        setFQuery("")
        // addNotification("success", "Filters reset!")
        // setSearchParams(prevState => ({
        //     ...prevState,
        //     fq: ""
        // }))
    }

    const resetSearch = () => {
        // Reset everything except Site & schema.

        localStorage.removeItem('lastquery')
        setFQuery("")
        // searchGadget_form.reset()
        const defaultQuery = getDefaultQuery()

        setSearchParams(defaultQuery)
    }


    /** 
     * To use:
     * GET => dispatch({ type: 'GET', field: '-' }).data
     * SET => dispatch({ type: 'SET', function: '-', history: - })
    */
    const dispatch = (...args) => {

        const Packet = {
            isLoading: false,
            isUpdate: false,
            hasError: false,
            data: []
        }

        Packet.data = null
        Packet.isLoading = isLoading
        Packet.isUpdate = isUpdate
        Packet.hasError = hasError

        if (args.length == 0) return Packet

        let action = args[0]


        if (action.type === "RELOAD") {
            setForceReload({})
            return Packet
        }

        // !Packet.hasError && SearchResult.total > 0
        if ((!Packet.hasError && !Packet.isLoading) || action?.overwriteLoadingErrorState) {

            try {
                switch (action.type) {

                    case 'GET':


                        if (action.field === 'RESULT') { Packet.data = SearchResult.items }

                        else if (action.field === 'RESULT_FIELDS') {
                            Packet.data = SearchResult.items_keys
                        }

                        else if (action.field === 'FACET_USED') { Packet.data = SearchResult.appliedFacet }


                        else if (action.field === 'FACET_USED_OBJECT') {
                            let facet = (SearchResult.facet) ? SearchResult.facet[2] : undefined
                            Packet.data = facet
                        }

                        else if (action.field === 'FACET_FIELDS') {
                            Packet.data = (SearchResult.facet) ? SearchResult.facet[0].facet_fields : undefined
                            let x = (SearchResult.facet) ? SearchResult.facet[1] : undefined
                            let schemaFilteredFacet = []

                            if (Packet.data && x) {
                                x.forEach(element => {
                                    schemaFilteredFacet = []
                                    if (element['items'].length > 0) {

                                        element['items'].forEach(item => {
                                            let index = Packet.data[element['field']].indexOf(item)

                                            schemaFilteredFacet.push(Packet.data[element['field']][index])
                                            schemaFilteredFacet.push(Packet.data[element['field']][index + 1])
                                        });
                                        Packet.data[element['field']] = schemaFilteredFacet
                                    }
                                });
                            }
                        }

                        else if (action.field === 'FACET_FIELDS_LABLES') { Packet.data = (SearchResult.facet) ? SearchResult.facet[1] : undefined }
                        else if (action.field === 'SORT') { Packet.data = SearchResult.sort }
                        else if (action.field === 'SCHEMA') { Packet.data = SearchResult.schema }
                        else if (action.field === 'TOTAL') { Packet.data = SearchResult.total }

                        else if (action.field === 'RESULT_INFO') {

                            Packet.data = {
                                total: SearchResult.total,
                                totalInCurrentPage: SearchResult.totalInCurrentPage,
                                currentPage: SearchResult.current_page,
                                perPage: SearchResult.per_page,
                                pageRange: SearchResult.page_range,
                            }

                        }

                        else if (action.field === 'COLLECTION') { Packet.data = SearchResult.collection }
                        else if (action.field === 'STATUS') { Packet.data = SearchResult.status }
                        else if (action.field === 'SEARCHED_TERM') {
                            if (SearchResult.searchedTerm === defaultSearchTerm || SearchResult.searchedTerm === '*:*')
                                Packet.data = ""
                            else Packet.data = SearchResult.searchedTerm
                        }
                        else if (action.field === 'COLLECTION_SCHEMAS') { Packet.data = SearchResult.collection_schemas }
                        else if (action.field === 'OPERATOR') { Packet.data = searchParams.operator }
                        else if (action.field === 'ADVANCED_FIELDS') { Packet.data = searchParams.advancedFields }
                        else if (action.field === 'FACET_USED_OBJECT_BY_FIELD') {
                            Packet.data = SearchResult.facet[1].filter(facet => facet.field === action.facetFieldName)[0]
                        }
                        else if (action.field === 'SEARCH_PARAMS') {
                            Packet.data = searchParams
                        }

                        else throw Error('ACTION.FIELD IS NOT DEFINED')

                        break;

                    case 'SET':

                        if (action.function === 'PAGE') setPage(action.page)

                        else if (action.function === 'PER_PAGE') setPerPage(action.perpage)

                        else if (action.function === 'SORT') setSort(action.field, action.direction)

                        else if (action.function === 'STATUS') setDirStatus(action.status)

                        else if (action.function === 'SITE') setWhichSite(action.site)

                        else if (action.function === 'FQ') setFQ(action.fq)

                        else if (action.function === 'REMOVE_FQ') removeFQ(action.fq)

                        else if (action.function === 'OPERATOR') setOperator(action.operator)

                        else if (action.function === 'REMOVE_ADVANCED_FIELD') removeAdvancedFieldByField(action.field)

                        else if (action.function === 'ADD_ADVANCED_FIELD') addAdvancedField(action.advancedField)

                        else if (action.function === 'SCHEMA') {
                            setSchema(action.schema)
                            resetSearch()
                        }
                        else if (action.function === 'HISTORY') {
                            // history = action.history
                        }
                        else if (action.function === 'QUERY') {
                            setQuery(action.query)
                        }

                        else throw Error('ACTION.FUNCTION IS NOT DEFINED')

                        break;

                    case 'RESET':

                        if (action.function === 'SORT') resetSort()
                        else if (action.function === 'FQS') resetFQs()
                        else if (action.function === 'ALL') resetSearch()

                        else throw Error('ACTION.FUNCTION IS NOT DEFINED')
                        break;

                    case 'CHECK':

                        if (action.function === 'IS_EMPTY' && action.field === 'FACET_USED_OBJECT') {
                            let facets = SearchResult.facet ? SearchResult.facet[2] : {}
                            Packet.data = Object.keys(facets).length === 0
                        }

                        else if (action.function === 'IS_EMPTY' && action.field === 'ADVANCED_FIELDS') {
                            Packet.data = true
                            Packet.data = searchParams?.advancedFields?.length === 0
                        }

                        else throw Error('ACTION.FUNCTION IS NOT DEFINED')
                        break;

                    default:

                        break;
                }
            } catch (error) {
                console.error(error);
                Packet.hasError = true
            }

        }

        return Packet
    }


    return (
        <SearchAppContext.Provider value={dispatch} >
            {props.children}
        </SearchAppContext.Provider>
    );
}