import React, { useContext, useState, useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { TreeViewFilters, MobileViewFilters } from './views'

import { SearchAppContext } from '@standards/context';
import { useViewport } from '@standards/hooks';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const SearchFilter = () => {

    const theme = useTheme()
    const {width, isMobile} = useViewport()


    const dispatch = useContext(SearchAppContext)

    const isFilterItemSelected = (filterItem) => {
        let selectedItems = dispatch({ type: 'GET', field: 'FACET_USED' }).data
        let isSelected = selectedItems.find(element => element.includes(filterItem)) !== undefined
        return (isSelected)
    }

    const addRemoveFilterItem = (filterItem) => {
        // if (!dispatch().isUpdate)
        dispatch({ type: 'SET', function: 'FQ', fq: filterItem })

        if (theme.breakpoints.values.md > width) scrollToTop()
    }


    const SearchFilterAdaptiveMainCompnent = () => {
        return (
            (theme.breakpoints.values.md > width) ? (
                <MobileViewFilters>
                    {TreeViewFiltersCompnent()}
                </MobileViewFilters>
            )
                :
                (
                    <div style={{ padding: "5px" }}>
                        {TreeViewFiltersCompnent()}
                    </div>
                )
        )
    }

    const TreeViewFiltersCompnent = () => {
        return (
            <TreeViewFilters
                filters={dispatch({ type: 'GET', field: 'FACET_FIELDS' }).data}
                filtersNickNames={dispatch({ type: 'GET', field: 'FACET_FIELDS_LABLES' }).data}
                facetUsedCounter={dispatch({ type: 'GET', field: 'FACET_USED_OBJECT' }).data}
                addRemoveFilterItem={addRemoveFilterItem}
                isFilterItemSelected={isFilterItemSelected}
                isLoading={!dispatch().isLoading}
                isUpdate={dispatch().isUpdate}
            />
        )
    }

    const SearchFilterSkeleton = (animation) => {
        return (<Skeleton variant="rectangular" height={250} width="95%" animation={animation} />)
    }

    const LoadingStateCompnent = () => {


        return (
            (theme.breakpoints.values.md > width) ? (
                <></>
            ):(
                <Stack spacing={1} alignItems="center" justifyContent="center" pt={1} pb={1}>
                {SearchFilterSkeleton('pulse')}
                {SearchFilterSkeleton('pulse')}
                {SearchFilterSkeleton('pulse')}
                {SearchFilterSkeleton('pulse')}
            </Stack>
            )
        )
    }

    const ErrorStateCompnent = () => {
        return (
            <Stack spacing={1} alignItems="center" justifyContent="center" pt={1} pb={1}>
                {SearchFilterSkeleton(false)}
                {SearchFilterSkeleton(false)}
                {SearchFilterSkeleton(false)}
                {SearchFilterSkeleton(false)}

            </Stack>
        )
    }

    return (
        <div>
            {
                !dispatch().isLoading ? (
                    !dispatch().hasError ? SearchFilterAdaptiveMainCompnent() : ErrorStateCompnent()
                ) : (LoadingStateCompnent())
            }
        </div >
    )
}

export default SearchFilter;