/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';

import { SearchApp } from '@standards/components';


/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: '/searchapp',
        component: SearchApp
      },
      // redirect to external links if path is in blacklist
      ...(config.settings?.externalRoutes || []).map((route) => ({
        ...route.match,
        component: NotFound,
      })),
      ...[
        // addon routes have a higher priority then default routes
        ...(config.addonRoutes || []),
        ...((config.settings?.isMultilingual && multilingualRoutes) || []),
        ...defaultRoutes.filter(entry => entry.path !== "/search"),
      ].map((route) =>
        config.settings.prefixPath
          ? {
            ...route,
            path: Array.isArray(route.path)
              ? route.path.map(
                (path) => `${config.settings.prefixPath}${path}`,
              )
              : `${config.settings.prefixPath}${route.path}`,
          }
          : route,
      ),
    ],
  },
];

export default routes;
