import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import { SearchAppContext } from '@standards/context'
import { Pdf, Link } from './'
import * as DOMPurify from 'dompurify';
import { Divider } from '@mui/material';
// import { doesStringContainHighlight } from 'common';

const doesStringContainHighlight = (string) => {
    var re = new RegExp(/<mark>.+?<\/mark>/gm);
    return re.test(string)
}

const Document = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(1),
    background: "#eceff163"
}));


const Content = styled(CardContent)(({ theme }) => ({
    display: "flex",
    justifyContent: "initial",
    [theme.breakpoints.down('md')]: {
        display: "block",
        justifyContent: "Center"
    },

}))

const MainContent = styled(Box)(({ theme }) => ({
    maxWidth: "50%",
    minWidth: "30%",
    [theme.breakpoints.down('md')]: {
        maxWidth: "100%"
    }
}))

const Details = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "2em",
    "& span": {
        background: "#eceff1",
        padding: "4px",
        borderRadius: "10px",
    },
    [theme.breakpoints.down('md')]: {
        margin: "0px"
    },

}))

const Field = styled(Box)(({ theme }) => ({
    paddingTop: "5px"
}))

const Description = styled(Typography)(({ theme }) => ({
    maxWidth: "50ch",
    [theme.breakpoints.down('md')]: {
        maxWidth: "100%"
    },
}))

const formatDate = date => {
    date = new Date(date)
    return (
        (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
    );
}

export default function CardView({ setSort }) {

    const dispatch = React.useContext(SearchAppContext)

    let headers = dispatch({ type: 'GET', field: 'RESULT_FIELDS' }).data
    let data = dispatch({ type: 'GET', field: 'RESULT' }).data
    let sort = dispatch({ type: 'GET', field: 'SORT' }).data

    const getFieldAliasByFieldName = (find) => {
        let found = find

        headers.map(header => {
            if (header.name == find && header.alias != "") {
                found = header.alias
            }
        })

        return found
    }

    const getFieldIgnoreCase = (obj, fieldName) => {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].toLowerCase() === fieldName.toLowerCase()) {
                return obj[keys[i]];
            }
        }
        return undefined;
    }

    const showDetails = () => headers.filter(header => header.details).length > 0

    return (

        data.map((document) => {

            const valueHTML = (field) => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getFieldIgnoreCase(document, field)) }} />

            return (
                <Document sx={{ minWidth: 275 }} key={document.uid}>
                    <Content>
                        <MainContent>
                            <Typography variant="h5" component="div">
                                {valueHTML('docid')}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="body1">
                                <Link
                                    url={document.url}
                                    value={valueHTML('title')}
                                />
                            </Typography>
                            <Description variant="body2">
                                {valueHTML("description")}
                            </Description>
                        </MainContent>
                        {
                            showDetails()
                            && <>
                                <Divider variant="middle" style={{ margin: "1rem" }} />
                                <Details>
                                    {
                                        headers
                                            .filter(header => !header.primary)
                                            .filter(header => header.details)
                                            .map(header => {

                                                let value = document[header.name]

                                                // check if column is a date
                                                var re = new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/gm);
                                                if (re.test(value)) {
                                                    value = formatDate(value)

                                                } else if (doesStringContainHighlight(value)) {
                                                    value = <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />;
                                                }

                                                return (
                                                    <Field key={header.primary}>
                                                        <b>{header.alias != "" ? header.alias.toUpperCase() : header.name.toUpperCase()}</b>
                                                        : <span>{value}</span>
                                                    </Field>
                                                )
                                            })
                                    }
                                </Details>
                            </>
                        }

                    </Content>
                    <CardActions>
                        <Pdf
                            docURL={document.url}
                            Id={""}
                        />
                    </CardActions>
                </Document>
            )
        })
    );
}