import * as React from 'react';
import debounce from 'lodash.debounce';

const WIDTH_CUT_OFF = 767;

const useViewport = () => {

  const [width, setWidth] = React.useState();
  const [height, setHeight] = React.useState();

  const [isMobile, setIsMobile] = React.useState();


  React.useEffect(() => {

    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setIsMobile(window.innerWidth < WIDTH_CUT_OFF)

    const handleWindowResize = debounce(() => {
      setWidth(window.innerWidth);
      // Set the height in state as well as the width
      setHeight(window.innerHeight);
    }, 1000)

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);

  }, []);

  React.useEffect(() => {

    // Only update isMobile if width has gone below the cut off
    if (window.innerWidth < WIDTH_CUT_OFF && !isMobile) {
      setIsMobile(true);
    } else if (window.innerWidth >= WIDTH_CUT_OFF && isMobile) {
      setIsMobile(false);
    }

  }, [width])

  // Return both the height and width
  return { width, height, isMobile };
}

export default useViewport